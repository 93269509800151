@mixin container {
  padding-left: 1.5rem;
  padding-right: 1.5rem !important;
}

@mixin flex_center_center($direction) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
}

@mixin flex_between_center($direction) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: $direction;
}

@mixin flex_between_start($direction) {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: $direction;
}

@mixin flex_center_stretch($direction) {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-direction: $direction;
}

@mixin flex_column {
  display: flex;
  flex-direction: column;
}

@mixin flex_row {
  display: flex;
  flex-direction: row;
}

@mixin grid_center {
  display: grid;
  align-items: center;
}

@mixin btn_and_navItem {
  color: $whiteColor;
  font-weight: 500;
}

@mixin flex_start($direction) {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: $direction;
}

@mixin img_video {
  width: 100%;
  height: auto;
}
