@import './variables';

* {
  margin: 0;
  padding: 0;
  list-style: none;
}

body {
  overflow-x: hidden;
}

.clickable:hover {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: inherit;
}

.main {
  position: relative;
  height: 100%;
  font-family: 'Inter', sans-serif;
  background-color: $main-bg;
  color: $main-color;

  .container {
    display: flex;

    .menuContainer {
      width: 250px;
      padding: 5px 20px;
      border-right: 2px solid $soft-bg;

      // @include lg{
      //   width: max-content;
      // }
    }

    .contentContainer {
      padding: 5px 20px;
      width: 100%;
    }
  }
}

.stroke-text {
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: $main-color;
}

button {
  border: none;
  outline: none;
  background: none;
}
