.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;

  .logoContainer {
    border-radius: 5px;
    padding: 10px;
    border: 1px solid #384256;

    .logo {
      font-size: 2rem;
      font-weight: 700;

      .dot {
        color: #0ff;
      }
    }
  }

  .user {
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;

    .userImage {
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      object-fit: cover;
    }

    .userName {
      font-size: 0.7rem;
    }
  }
}
