// @import './NotFound.scss';
@import '../../assets/styles/base/_mixins.scss';

.notFoundContainer {
  background-image: url('../../assets/images/404.jpg');
  background-repeat: no-repeat;
  padding: 3rem;
  height: 100vh;
  width: 100%;
  background-color: white;
  background-size: 50%;
  background-position: center;
  background-attachment: fixed;
  overflow: hidden;
  gap: 2rem;

  .back-to-home {
    @include flex_center_center(row);

    color: green;
    font-size: 2rem;
    font-weight: 700;
    gap: 1rem;
    transform: scale(1.15);
    animation: anim_my_name 2.5s infinite;

    @keyframes anim_my_name {
      0% {
        transform: translate(2rem, 4rem);
        z-index: 99;
      }

      100% {
        transform: translate(0, 0);
      }
    }
  }

  .text-container {
    text-align: center;
    font-size: 1.5rem;
    color: red;
  }
}
