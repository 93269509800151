// BG
$main-bg: #2a3447;
$soft-bg: #384256;
$dark-bg: #222b3c;

//TEXT
$main-color: white;
$soft-color: #ddd;
$dark-color: #2a3447;

$mainCardsShadow: 0 2px 4px rgba(140, 140, 141, 0.549);
